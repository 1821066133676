import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";

import HeaderLower from "./HeaderLower";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";

const UserformSurvey = (props) => {
  const [ans1, setAns1] = useState(" ");
  const [ans2, setAns2] = useState(" ");
  const [ans3, setAns3] = useState(" ");
  const [ans4, setAns4] = useState(" ");
  const [ans5, setAns5] = useState(" ");
  const [ans6, setAns6] = useState(" ");

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);

  const TITLE =
    process.env.REACT_APP_API_SITE_TITLE + " | Customer Survey Form";
  const navigate = useNavigate();

  // FIXME
  localStorage.setItem("returnpage", true);
  localStorage.setItem("page", "/survey-form");

  // StorageService.setReturnStatus(true);
  // StorageService.setPage("/UserFormSurvey");

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("voucher");
    localStorage.removeItem("page");
    localStorage.removeItem("ans1");
    localStorage.removeItem("ans2");
    localStorage.removeItem("ans3");
    localStorage.removeItem("ans4");
    localStorage.removeItem("ans5");
    localStorage.removeItem("ans6");
    window.location.replace("/");
  };
  const handleChangeans1 = (event) => {
    const ans1 = event.target.value;
    setAns1(ans1);
    localStorage.setItem("ans1", ans1);
  };
  const handleChangeans2 = (event) => {
    const ans2 = event.target.value;
    setAns2(ans2);
    localStorage.setItem("ans2", ans2);
  };
  const handleChangeans3 = (event) => {
    const ans3 = event.target.value;
    setAns3(ans3);
    localStorage.setItem("ans3", ans3);
  };
  const handleChangeans4 = (event) => {
    const ans4 = event.target.value;
    setAns4(ans4);
    localStorage.setItem("ans4", ans4);
  };
  const handleChangeans5 = (event) => {
    const ans5 = event.target.value;
    setAns5(ans5);
    localStorage.setItem("ans5", ans5);
    // console.log(ans5);
  };
  const handleChangeans6 = (event) => {
    const ans6 = event.target.value;
    setAns6(ans6);
    localStorage.setItem("ans6", ans6);
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }
  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      localStorage.setItem("userformopen", "ture");
      // StorageService.setFormStatus(true);
      if (captcha) {
        secureLocalStorage.setItem("Survey", {
          ans1: ans1,
          ans2: ans2,
          ans3: ans3,
          ans4: ans4,
          ans5: ans5,
          ans6: ans6,
        });
        navigate("/user-form");
      }
    } else {
      localStorage.setItem("userformopen", false);
      // StorageService.setFormStatus(false);
    }
  };

  const validate = () => {
    let errors = {};
    let formIsValid = true;

    if (ans1 === " ") {
      formIsValid = false;
      errors["ans1"] = "Please select the option";
      setErrors(errors);
      return false;
    }

    if (ans2 === " ") {
      formIsValid = false;
      errors["ans2"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans3 === " ") {
      formIsValid = false;
      errors["ans3"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans4 === " ") {
      formIsValid = false;
      errors["ans4"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans5 === " ") {
      formIsValid = false;
      errors["ans5"] = "Please select the option";
      setErrors(errors);
      return false;
    }
    if (ans6 === " ") {
      formIsValid = false;
      errors["ans6"] = "Please select the option";
      setErrors(errors);
      return false;
    }

    if (!captcha) {
      formIsValid = false;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);
      return false;
    }
    return formIsValid;
  };

  const Voucher = localStorage.getItem("voucher");
  const userform = localStorage.getItem("userform");

  // const Voucher = StorageService.getVoucher();
  // const userform = StorageService.getUserformStatus();
  // if (userform === true) {
  //   return <Redirect to="/UserForm" />;
  // }
  useEffect(() => {
    if (userform === "true") {
      navigate("/user-form");
    }

    if (Voucher === undefined || Voucher == null) {
      localStorage.setItem("returnpage", false);
      localStorage.setItem("userform", false);
      localStorage.removeItem("page");
      localStorage.removeItem("ans1");
      localStorage.removeItem("ans2");
      localStorage.removeItem("ans3");
      localStorage.removeItem("ans4");
      localStorage.removeItem("ans5");
      navigate("/");
    }
    if (Voucher === undefined || Voucher == null) {
      StorageService.destroyVoucherInfo1();
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div className="white-box form">
          <form
            name="userinfo"
            className="form-outer register clearfix"
            id="userinfo"
            onSubmit={submituserRegistrationForm}
            method="post"
            action=""
          >
            <div className="row">
              <h1 style={{ textAlign: "center" }}>
                {process.env.REACT_APP_SURVEYTITLE}
              </h1>
              <label>
                <b>1</b>. On a scale from 1 to 5, please rate your overall
                satisfaction with your Test Rest experience with the Sales
                Associate: <span>*</span>
              </label>
              <div className="boxheading">
                <label className="heading1">
                  <b>Highly Dissatisfied</b>
                </label>
                <label className="heading1 ml-5">
                  <b> Highly Satisfied</b>
                </label>
              </div>
              <br />

              <label className="inline">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="1"
                  onChange={handleChangeans1}
                />{" "}
                1
              </label>

              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="2"
                  onChange={handleChangeans1}
                />{" "}
                2
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="3"
                  onChange={handleChangeans1}
                />{" "}
                3
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="4"
                  onChange={handleChangeans1}
                />{" "}
                4
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="satisfaction_radio"
                  name="satisfaction_radio"
                  value="5"
                  onChange={handleChangeans1}
                />{" "}
                5
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans1}</div>
                <span style={{ color: "red" }} id="satisfaction_msg"></span>
              </label>
              <hr />

              <label>
                <b>2</b>. Did the Sales Associate begin the Test Rest
                presentation by custom fitting you on our 'Discover Your Sleep'
                System?<span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="Y"
                  onChange={handleChangeans2}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="mattress_radio"
                  name="mattress_radio"
                  value="N"
                  onChange={handleChangeans2}
                />{" "}
                No
              </label>
              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans2}</div>
                <span style={{ color: "red" }} id="mattress_msg"></span>
              </label>
              <hr />
              <label>
                <b>3</b>. Were you then introduced to a Tempur-Pedic mattress?
                <span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="Y"
                  onChange={handleChangeans3}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="experience_radio"
                  name="experience_radio"
                  value="N"
                  onChange={handleChangeans3}
                />{" "}
                No
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans3}</div>
                <span style={{ color: "red" }} id="experience_msg"></span>
              </label>

              <hr />

              <label>
                <b>4</b>. Did the Sales Associate inform you how you can sleep
                up to 8 degrees cooler on a Tempur-Pedic Breeze mattress?
                <span>*</span>
              </label>
              <label className="inline">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="Y"
                  onChange={handleChangeans4}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="stomach_radio"
                  name="stomach_radio"
                  value="N"
                  onChange={handleChangeans4}
                />{" "}
                No
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans4}</div>
                <span style={{ color: "red" }} id="stomach_msg"></span>
              </label>
              <hr />

              <label>
                <b>5</b>. Were you introduced to a Power Base?<span>*</span>
              </label>

              <br />
              <label className="inline">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="Y"
                  onChange={handleChangeans5}
                />{" "}
                Yes
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="store_radio"
                  name="store_radio"
                  value="N"
                  onChange={handleChangeans5}
                />{" "}
                No
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans5}</div>
                <span style={{ color: "red" }} id="store_msg"></span>
              </label>

              <label>
                <b>6</b>. Were you introduced to a Tempur-Pedic Ergo Smart Base
                (the base that responds to snoring automatically)?<span>*</span>
              </label>
              <div className="boxesheading">
                <label className="heading1">
                  <b>Not At All Likely</b>
                </label>
                <label className="heading1 ml-3">
                  <b> Extremely Likely</b>
                </label>
              </div>
              <br />
              <label className="inline">
                <input
                  type="radio"
                  id="smart_base"
                  name="smart_base"
                  value="1"
                  onChange={handleChangeans6}
                />{" "}
                1
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="smart_base"
                  name="smart_base"
                  value="2"
                  onChange={handleChangeans6}
                />{" "}
                2
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="smart_base"
                  name="smart_base"
                  value="3"
                  onChange={handleChangeans6}
                />{" "}
                3
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="smart_base"
                  name="smart_base"
                  value="4"
                  onChange={handleChangeans6}
                />{" "}
                4
              </label>
              <label className="inline ml-2">
                <input
                  type="radio"
                  id="smart_base"
                  name="smart_base"
                  value="5"
                  onChange={handleChangeans6}
                />{" "}
                5
              </label>

              <label className="inline ml-2">
                <div className="errorMsg">{errors.ans6}</div>
                <span style={{ color: "red" }} id="smart_base_msg"></span>
              </label>

              <br />
              <br />

              <div className="overflow-hider">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                  onChange={onCaptchaChange}
                />
              </div>
              <div className="errorMsg">{errors.checkederrorcaptcha}</div>

              <span style={{ color: "red" }} id="response_msg"></span>
              <br />
            </div>
            <input
              // onClick={() => myTimeout()}
              type="submit"
              className="submit-btn survey"
              name="submit1"
              value="Submit and Move Next"
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default UserformSurvey;
