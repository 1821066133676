import axios from "axios";
import { Buffer } from "buffer";
const API_URL = process.env.REACT_APP_API_Link;
const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;
const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");

const getStates = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStates",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const getEmployees = (store) => {
  return axios({
    method: "GET",
    url: API_URL + "getEmployees/" + store,
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};
const getStoreLocation = () => {
  return axios({
    method: "GET",
    url: API_URL + "getStoreLocation",
    headers: {
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const regData = (bodyFormData) => {
  return axios({
    method: "POST",
    url: API_URL + "registerDataElevenFieldsWithoutShowroom",
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${token}`,
      "Access-Control-Allow-Origin": API_ORIGIN,
    },
  });
};

const RegisterService = {
  getStates,
  regData,
  getEmployees,
  getStoreLocation,
};

export default RegisterService;
